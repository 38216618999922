import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const SecondPage = ({ pageContext }) => (
    <Layout>
        {auth => (
            <div>
                <SEO title="Page two" />
                <h1>Hi from {pageContext.page && pageContext.page.title}</h1>
                <p>You are looogged... {auth.isAuthed ? 'in' : 'out'}!</p>
                <p>{pageContext.page && pageContext.page.body}</p>
                <Link to="/">Go back to the homepage</Link>
            </div>
        )}
    </Layout>
)

export default SecondPage;
